<template>
  <div class="main">
    <h2>{{ $t('msg.task.title') }}</h2>
    <p class="my-4">{{ $t('msg.task.subtitle') }}</p>
    <div class="card">
      <div class="card-body px-5 py-3">
        <a-list
          :data-source="results">
          <a-list-item
            slot="renderItem"
            slot-scope="item">
            <a-list-item-meta>
              <h5 slot="title">
               {{ item.name }}
              </h5>
              <div slot="description">
                <div class="text-secondary">
                  {{ item.description }}.
                </div>
                <div class="text-default">
                  <span v-if="item.executed">
                    {{ $t('msg.task.executed') }} {{ moment(String(item.executed)).calendar() }}
                  </span>
                  <span v-else>
                    {{ $t('msg.task.unexecuted') }}
                  </span>
                  <a-divider type="vertical" />
                  <span v-if="item.scheduled">
                    {{ $t('msg.task.scheduled') }} {{ moment(String(item.scheduled)).calendar() }}
                  </span>
                  <span v-else>
                    {{ $t('msg.task.unscheduled') }}
                  </span>
                </div>
              </div>
            </a-list-item-meta>
            <a-statistic-countdown
              :value="item.scheduled"
              @finish="fetch"
              class="d-none d-md-inline" />
          </a-list-item>
        </a-list>
      </div>
    </div>
  </div>
</template>
<script>
import lms from '@/api/lms'
import moment from 'moment'
export default {
  name: 'TaskList',
  data: function () {
    return {
      moment,
    }
  },
  computed: {
    results() {
      return this.$store.getters['operation/TASKS']
    },
  },
  mounted() {
    this.fetch()
  },
  methods: {
    fetch() {
      return lms.task.search()
        .then(response => this.$store.commit('operation/SET_TASKS', response.data))
    },
  },
}
</script>
